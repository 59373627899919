.stats-table {
    width: 100%;
    border-collapse: collapse;
    color: #333;
    font-size: 0.95em;
  }
  
  .stats-table th,
  .stats-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .stats-table tbody tr:hover {
    background-color: #bdbdbd;
  }
  
  .stats-table th {
    background-color: #AAAAAA;
    color: black;
  }
  