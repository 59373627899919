.navbar {
  background-color: #D9D9D9;
  height: auto; /* Allow height to adjust for wrapped items on small screens */
  width: 100%; /* Full width */
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar .link {
  box-sizing: border-box;
  display: block;
  color: black;
  text-align: center;
  padding: 18px 16px;
  text-decoration: none;
}

.navbar .link.active-link {
  background-color: #666666;
  color: white;
  width: auto; /* Let's allow this to adjust to content */
}

.navbar .link:hover {
  background-color: #111;
  color: white;
}

@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column; /* Stack items vertically */
  }

  .navbar .link {
    width: 100%; /* Let’s use the full width for each link on small screens */
    text-align: left; /* Optional: left align text on small screens */
  }
}
