/* App.css */
  
/* Style for the portfolio table */
table {
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
}
thead {
    background-color: #fff;
  }
  
  tbody {
    background-color: #fff;
  }
th,
td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Style for the investment form */
form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

label {
    margin-top: 10px;
}

input[type='text'],
input[type='number'],
button {
    margin-top: 5px;
    padding: 5px;
    border-radius: 5px;
}

button {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #3e8e41;
}

.App-container {
    background-color: #9f9898;
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    margin: auto;
}