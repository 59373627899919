/* Base styles */
body {
    font-family: Arial, sans-serif;
}

button {
    padding: 8px 12px;
    margin: 5px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

button:hover:not(:disabled) {
    background-color: #0056b3;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    padding: 8px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

h3 {
    margin-top: 20px;
    color: #333;
}

p {
    color: #666;
    font-size: 14px;
}

/* Alert messages */
p[role="alert"] {
    color: red;
    font-weight: bold;
}

/* Sign-in & Sign-up form */
form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

/* Group related styles */
div {
    margin-bottom: 20px;
}

ul {
    list-style-type: none;
    padding: 0;
}

ul li {
    padding: 6px 0;
    border-bottom: 1px solid #eee;
}

ul li:last-child {
    border-bottom: none;
}
