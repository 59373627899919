@media (max-width: 768px) {
 .card-container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px; 
        padding: 16px; /* Optional: added some padding to card-container for aesthetics */
    }
    
    .card {
        border: 1px solid #ddd;
        padding: 16px;
        border-radius: 8px;
        width: calc(50% - 8px); /* As the gap is 16px, subtracting 8px will accommodate the gap evenly on both sides of the card */
        box-sizing: border-box; /* Ensures that padding and border are included in the element's total width and height */
    }
    
    h3 {
        margin-top: 0;
    }
    
    p {
        margin-bottom: 0.5em;
    }
}

/* Adjust the row height */
.table-version tr {
    line-height: 1.2; /* Adjust to your preference */
    padding: 5px 0;   /* Reduce or increase padding as required */
  }
  
  /* Adjust the cell styling */
  .table-version td {
    padding: 2px 2px; /* Adjust to your preference */
    font-size: 0.9rem; /* Adjust to your preference */
  }
  
  /* Adjust the star button */
  .table-version button {
    padding: 2px 5px;  /* Reduce or increase padding as required */
    font-size: 0.9rem; /* Adjust to your preference */
  }
  